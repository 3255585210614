// 
// layout: page
//

body{
    overflow-x: hidden;
}

.page{
    overflow: hidden;
    &-header {
        padding-top: 0;
    }
}

.page-content{
    position: relative;
    z-index: 2;
}

@include media-breakpoint-down(xl) { }

@include media-breakpoint-down(lg) { }

@include media-breakpoint-down(md) { }

@include media-breakpoint-down(sm) {
    .page{
        padding-top: 70px;
    }
}






