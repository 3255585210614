// 
// page: about
// 


.section-about {

}

.section-chefs {
  padding-bottom: 50px;
  .chef-content {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 20px;
  }
  h6 {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: bold;

  }
  h5 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 25px;
  }
  p {
    font-weight: 300;
  }
}


@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(sm) {


}


