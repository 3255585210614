// 
// page: home
//
.page-home {

}

.section-home-about {
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fefefb;
  &:before {
    content: '';
    background-color: #fff;
    position: absolute;
    right: 50vw;
    left: 0;
    top: 0;
    bottom: 0;
  }

  h2, .h2 {
    font-size: 60px;
    margin-bottom: 40px;
  }
  p {
    font-weight: 300;
    max-width: 320px;
    color: rgba($text-color, .7);
  }
  .btn {
    margin-top: 40px;
  }
  .about-content {
    padding: 240px 0;
  }

  .about-image-wrapper {
    align-self: center;
    height: 100%;
    position: relative;
    .about-image-1, .about-image-2 {
      position: absolute;
      width: 60%;
      img {
        width: 100%;
      }
    }
    .about-image-1 {
      right: 0;
      top: 10%;
    }
    .about-image-2 {
      left: 0;
      bottom: 10%;
    }
  }
}

.section-home-banners {
  .banner-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 300px 0;
    text-align: center;
    color: $white;
    display: block;
    &:after {
      content: '';
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: rgba($black, .6);
      transition: all .25s;
    }
    &:hover:after {
      background-color: rgba($black, .3);
    }
  }
  .banner-index {
    position: relative;
    z-index: 5;
  }
  h2, .h2 {
    color: $white;
  }
  .banner-inner {
    max-width: 320px;
    margin: 0 auto;
    text-align: left;
    .banner-text {
      padding: 0 40px 30px;
      margin-top: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid $gray-300;
      p {
        color: rgba($white, .8);
      }
    }
    .banner-open {
      padding: 0 15px;
      color: rgba($white, .8);
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 300;

    }
  }
}



@include media-breakpoint-down(xl) {
  .section-home-about {
    .about-content {
      padding: 170px 0;
    }
  }

}

@include media-breakpoint-down(lg) {
  .section-home-about {
    .about-content {
      padding: 130px 0;
    }
  }
  .section-home-banners {
    .banner-bg {
      padding: 150px 0;
    }
  }
}
@include media-breakpoint-between(lg, lg) {
  .section-home-about {
    background-image: none !important;
  }
}

@include media-breakpoint-down(md) {
  .section-home-about {
    background-position: right top;
    &:before {
      display: none;
    }
    .about-content {
      padding: 50px 0;
    }
    .about-image-wrapper {
      padding-bottom: 50%;
      margin-left: -5px;
      margin-right: -5px;
      img { width: 100%; }
      .about-image-1 {
        position: relative;
        width: 75%;
        top: 0;
        right: 0;

      }
      .about-image-2 {
        width: 75%;
        left: 25%;
        bottom: auto;
        top: 25%;

      }
    }
  }
  .section-home-banners {
    .banner-bg {
      padding: 100px 0;
    }
  }

}

@include media-breakpoint-down(sm) {
  .section-home-about {
    background-image: none !important;
  }
  .section-home-banners {

  }

}


