// 
// page: menu
// 

.section-menu {
  background-image: url(../img/menu_bg.jpg);
  background-position: top center;
  background-size: cover;
  padding: 45px 0;
  &:before {
    background: url(../img/menu_left_bottom.png) no-repeat left bottom;
    position: absolute;
    top: 0;
    left: 20px;
    right: 0;
    bottom: 20px;
    content: '';
  }
  &:after {
    background: url(../img/menu_right_bottom.png) no-repeat right bottom;
    position: absolute;
    top: 0;
    right: 20px;
    left: 0;
    bottom: 20px;
    content: '';
  }

  h3, .h3 {
    color: $white;
    margin-bottom: 5px;

  }

  h2, .h2 {
    margin-top: -10px;
    color: $primary;
  }

  .menu-nav-tabs, .menu-tab-content {
    position: relative;
    z-index: 5;
  }
  .menu-nav-tabs {
    margin-top: 60px;
    .nav {
      display: block;
      .nav-item {
        .nav-link {
          font-size: 20px;
          position: relative;
          padding: 20px 0;
          text-transform: uppercase;
          &:hover, &.active {
            color: $primary_light;
            &:after {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              font-family: 'icomoon' !important;
              font-size: 10px;
              content: "\e902";
            }
          }
        }
      }
    }

  }
  .menu-tab-content {
    .tab-content {
      margin-top: -25px;
      .detail-acrodion {
        margin-bottom: 0;
        padding-top: 65px;
        padding-bottom: 30px;
      }
    }
    li.item {
      position: relative;
      margin-bottom: 20px;

      & > a {
        color: $primary;
        border-bottom: 1px solid $gray-300;
        font-size: 23px;
        line-height: 21px;
        font-weight: bold;
        display: block;
        padding: 20px 15px;
        &[data-toggle="collapse"] {
          &.collapsed {
            color: $white;

            &:hover {
              color: $primary;
              &:before {
                color: $primary;
              }
            }

            &:before {
              color: $white;
              content: "\e914";
            }
          }

          &:before {
            font-family: 'icomoon' !important;
            font-size: 10px;
            position: absolute;
            right: 25px;
            top: 25px;
            color: $primary;
            content: "\e910";
            transition: all .25s;
          }
          &:hover {
            color: $white;
            &:before {
              color: $white;
            }
          }
        }
      }
    }
    .collapse-inner {
      background-color: $white;
      position: relative;
      &:before {
        background: url(../img/menu_inside_left_bottom.png) no-repeat left bottom;
        position: absolute;
        top: 0;
        right: 0;
        left: -8px;
        bottom: -6px;
        content: '';
      }
      &:after {
        background: url(../img/menu_inside_right_top.png) no-repeat right top;
        position: absolute;
        top: -4px;
        right: -8px;
        left: 0;
        bottom: 0;
        content: '';
      }
    }
  }

  .menu-download {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    span {
      font-size: 14px;
      color: $white;

    }
    a.download-link {
      margin-left: 15px;
      text-transform: uppercase;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      i {
        align-self: center;
        margin-left: 5px;
        font-size: 18px;
      }
      &:hover {
        color: $primary;
      }
    }
  }

  ul.menu-list {
    padding: 15px 40px;
    position: relative;
    z-index: 3;
    li {
      .menu-list-item {
        border-bottom: 1px dashed #f0dcde;
        display: flex;
        //align-items: center;
        padding: 15px 0;

        .list-item-description {
          flex: 1;

          h6, .h6 {
            font-size: 15px;
            font-weight: bold;
            color: $text-color;
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            font-weight: 300;
            color: #060606;
            margin-bottom: 0;
          }
        }

        .list-item-price {
          width: 30%;
          text-align: right;
          align-self: center;

          .price {
            font-weight: 400;
            font-size: 30px;
            line-height: 30px;

            span {
              margin-left: 4px;
              font-weight: 400;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
  .section-menu {
    &:before, &:after {
      display: none;
    }
  }

}

@include media-breakpoint-down(md) {
  .section-menu {

    .menu-nav-tabs {
      margin-top: 20px;

      .nav {
        display: flex;

        .nav-item {
          margin-right: 10px;
          .nav-link {
            padding: 10px;

            &:hover, &.active {
              background-color: $primary;
              color: $white;
            }

            &:after {
              display: none;
            }

          }
        }
      }
    }
    .menu-download {
      justify-content: center;
      margin-right: 0;
    }

    .menu-tab-content {
      .tab-content {
        margin-top: 30px;

        .detail-acrodion {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
    }
  }

}

@include media-breakpoint-down(sm) {

}


