
.section{
    position: relative;
}

.section-relative{
    position: relative;
}

.section-title {
    margin-top: 30px;
    margin-bottom: 30px;
    h1, .h1 {
        font-size: 60px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.section-builders {
    &:before {
        content: '';
        background-image: url(../img/about_bg.png);
        background-repeat: no-repeat;
        background-position: right top;
        background-color: #fdfefb;
        background-size: 100%;
        position: absolute;
        right: 0;
        left: 50%;
        top: -170px;
        height: 1600px;
    }
    h4 {
        font-weight: bold;
        margin-bottom: 30px;
    }
    h6 {
        margin-bottom: 30px;
    }
    p {
        font-weight: 200;
    }
    .box {
        position: relative;
        margin-bottom: 125px;
        padding: 200px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.box-left {
            .box-image {
                left: 0;
                right: auto;
            }
        }
        .box-image {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 50%;
            .box-image-bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: $primary;
                background-size: cover;
                background-position: center center;
            }
        }
    }
}

@include media-breakpoint-down(xxl) {
}
@include media-breakpoint-down(xl) {
    .section-builders {
        &:before {
            background-image: none;
        }
    }

}
@include media-breakpoint-down(lg) {

}


@include media-breakpoint-down(md) {
    .section-title {
        h1, .h1 {
            font-size: 45px;
        }
    }
    .section-builders {
        h4 {
            font-weight: bold;
            margin-bottom: 15px;
        }
        .box {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-bottom: 10px;

            .box-image {
                position: relative;
                width: 100%;
                padding-bottom: 62%;
                .box-image-bg {
                }
            }
            &.box-right {
                flex-direction: column-reverse;
            }
            .box-content {
                padding-top: 20px;
                padding-bottom: 5px;
            }
        }
    }

}


@include media-breakpoint-down(sm) {

}



