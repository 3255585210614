// 
// page: voucher
// 

.section-voucher {
  &:before {
    content: '';
    background-image: url(../img/contact_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #fefefb;
    background-size: 100%;
    position: absolute;
    right: 0;
    left: 50vw;
    top: 0;
    bottom: 100px;
  }

  h1, .h1 {
    font-size: 60px;
    margin-bottom: 80px;
  }
  h4, .h4 {
    margin-bottom: 40px;
  }
  p {
    font-weight: 300;
    max-width: 320px;
    color: rgba($text-color, .7);
  }
  .voucher-content {
    padding: 60px 0 120px;
  }

  .voucher-image {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
  }
}

.section-voucher-form {
  padding: 100px 0;
  h5, .h5 {
    font-size: 26px;

  }
  h2, .h2 {
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
  }
}


@include media-breakpoint-down(xl) {
  .section-voucher {
    .voucher-content {
    }
  }

}

@include media-breakpoint-down(lg) {
  .section-voucher {
    .voucher-content {
      h1, .h1 {
        margin-bottom: 30px;
      }
    }
    .voucher-image {
      padding-bottom: 30px;
    }
  }
  .section-voucher-form {
    padding-top: 0;

    h2, .h2 {
      font-size: 40px !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .section-voucher {
    .voucher-content {
      padding: 50px 0;
    }
    &:before {
      bottom: 0;
      background-image: none !important;
    }
  }
  .section-voucher-form {
    padding-bottom: 10px !important;
    h2, .h2 {
      font-size: 30px !important;
    }
  }

}

@include media-breakpoint-down(sm) {
  .section-voucher {
    h1, .h1 {
      font-size: 45px;
    }
    &:before {
      display: none;
    }
  }
  .section-voucher-form {
    h2, .h2 {
      font-size: 30px !important;
    }
  }

}

@include media-breakpoint-down(xs) {
  .section-voucher {
  }

}


