// 
// layout: footer
//

.footer{
    position: relative;
    border-top: 1px solid #d9d9d9;
    .footer-wrapper{
        position: relative;
        z-index: 2;
        padding: 30px 0 30px 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }


    .footer-brand{
        text-align: center;
        align-self: center;
        flex:1;
        img{

        }
    }

    .footer-copyright{
        padding: 0 30px;
        width: 75%;
        display: flex;
        justify-content: space-between;
        align-self: center;
        p, a{
            color: $black;
            margin-bottom: 0;
            font-weight: 300;
        }
        a{
            font-weight: bold;
            &:hover{
                color: $primary;
            }
        }
    }
    ul.footer-companies{
        display: flex;
        flex:1;
        margin-bottom: 0;
        align-self: center;
        align-items: center;
        justify-content: center;
        li {
            margin-left: 10px;
            margin-right: 10px;
        }
        li a {
            display: block;

        }
        li a img {
            max-height: 80px;
        }
    }
    ul.footer-social{
        display: flex;
        align-items: center;
        flex:1;
        padding-left: 50px;
        margin-bottom: 0;

        li {
            margin-right: 20px;

        }
        li a {
            color: $black;
            font-size: 20px;
            &:hover{
                color: $primary;
            }
        }
    }
}


@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(lg) {
    .footer{
        .footer-copyright {
            padding-left: 25px;
            padding-right: 25px;
        }
        ul.footer-social {
            padding-left: 25px;
        }
    }
}


@include media-breakpoint-down(md) {
    .footer{
        .footer-brand {
            margin-right: 0;
            margin-bottom: 1rem;
        }
        .footer-wrapper {
            flex-direction: column;
            align-items: center;
        }
        .footer-copyright {
            border: none;
            padding: 0;
            margin-bottom: 1rem;
            flex-wrap: wrap;
        }
        ul.footer-social {
            margin-top: 2rem;
            border: none;
            padding-left: 0;
        }
    }
}


@include media-breakpoint-down(sm) {
    .footer {
        .footer-wrapper {
            display: block;
            align-items: center;
        }
        .footer-copyright {
            width: 100%;
            max-width: 320px;
            margin: 0 auto;
            display:block;
            text-align: center;
        }
        ul.footer-social {
            width: 100%;
            justify-content: center;
        }
    }
}
