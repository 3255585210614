// 
// page: gallery
// 

.section-gallery {
  .nav-filter {
    display: flex;
    margin-bottom: 15px;
    margin-left: -20px;
    li.filter-mansory {
      margin-right: 25px;
      margin-bottom: 0;
      a {
        display: block;
        color: $text-color;
        text-transform: uppercase;
        font-size: 20px;
        padding: 15px 20px;
        margin-bottom: 0;
      }
      &.active a, a:hover {
        color: $primary;
      }
    }
  }
  ul.grid-mansory {
    margin-bottom: 0;
  }
  .mansory-item {
    display: block;
    position: relative;
    padding-bottom: 62.5%;
    outline: none !important;
    margin: 0 -2px;
    .mansory-image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-position: 50%;
    }
    .mansory-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($black, .4);
      transition: all .4s;
    }
    &:hover .mansory-mask {
      background-color: transparent;
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@include media-breakpoint-down(md) {
  .section-gallery {
    .nav-filter {
      flex-wrap: wrap;
      margin-left: 0;
      li.filter-mansory a {
        font-size: 18px;
        padding: 15px;
      }
    }
  }

}

@include media-breakpoint-down(sm) {

}


