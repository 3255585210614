//
// component: hero
//

.hero-background{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100vh;
    overflow: hidden;
    z-index: 20;
    &:after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba($black, .5);
    }
    .hero-image {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-size: cover;
        background-position: center 20%;
        background-repeat: no-repeat;
        animation: kenburns 30s;
    }
}

.hero-small {
    min-height: 650px;
}

.hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    text-align: center;
    min-width: 310px;
    max-width: 100%;
    h3, .h3 {
        color: $primary;
        position: relative;
        z-index: 5;
    }
    h1, .h1 {
        color: $white;
        margin-bottom:0;
        position: relative;
        z-index: 5;
    }
}


.main-scroll {
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    .link-top {
        position: relative;
        padding-bottom: 90px;
        text-transform: uppercase;
        line-height: 1;
        display: block;
        font-weight: 600;
        font-size: 12px;
        transition: all 0.3s;
        animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
        &:hover {
            color: $white;
        }
    }

    .link-top:before {
        content: "\e900";
        font-family: 'icomoon' !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        bottom: 15px;
        font-size: 45px;
        transition: all 0.3s;
    }
}
@keyframes anim-bounce-sm {
    from {
        top: 0px; }
    to {
        top: 7px; }
}

@include media-breakpoint-down(xl) {
    .hero-background{
        min-height: 700px;
    }
    .hero-background.hero-small {
        min-height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 65px;
        }
    }
}
@include media-breakpoint-down(lg) {
    .hero-background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50%;
    }
    .hero-background{
        min-height: 600px;
    }
    .hero-background.hero-small {
        min-height: 400px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 45px;
        }
        h3, .h3 {
            font-size: 26px;
        }
    }
}

@include media-breakpoint-down(md) {
    .hero-background{
        min-height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 35px;
        }
        h3, .h3 {
            font-size: 22px;
        }
        .hero-border {
            padding: 50px 75px;

        }
    }
    .hero-background.hero-small {
        min-height: 300px;
    }
}

@include media-breakpoint-down(sm) {
    .hero-background.hero-small {
        //min-height: 5vh;
    }
    .hero-background {
        min-height: 400px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 34px;
        }
        h3, .h3 {
            font-size: 20px;
        }
        .hero-border {
            padding: 50px 25px;

        }
    }
    .hero-background.hero-small {
        min-height: 150px;
    }
}