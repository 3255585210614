// 
// page: voucher
// 

.section-contact {
  padding: 60px 0;
  &:before {
    content: '';
    background-image: url(../img/contact_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #fefefb;
    background-size: 100%;
    position: absolute;
    right: 0;
    left: 50vw;
    top: 0;
    bottom: 180px;
  }
  h1, .h1 {
    margin-bottom: 100px;
  }
  h5, .h5 {
    margin-bottom: 20px;
  }
  .contact-content {
  }
  p {
    font-weight: 300;
    color: rgba($text-color, .7);
  }
  .contact-opens {
    border-top: 1px solid $gray-300;
    margin-left: -45px;
    padding: 0 45px;
    padding-top: 40px;
    margin-top: 40px;
    .contact-open {
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      color: rgba($text-color, .7);
      strong {
        font-weight: bold;
      }
    }
  }
  .contact-form {
    .form-control {
      background-clip: unset;
    }
    .form-control[type=email] {
      border-left: 0;
    }
    .custom-control {
      margin-bottom: 40px;
      margin-top: 10px;
    }

  }

}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
  .section-contact {
    &:before {
      background-image: none;
    }
  }

}

@include media-breakpoint-down(md) {
  .section-contact {
    &:before {
      display: none;
    }
    h1, .h1 {
      margin-bottom: 40px;
    }
    .contact-opens {
      padding-top: 20px;
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .contact-form {
      margin-top: 30px;
      .form-control[type=email] {
        border-left: 1px solid $gray-300;
      }
    }
  }

}

@include media-breakpoint-down(sm) {

}


